.content {
  display: grid;
}
div#script_stage {
  width: 81%;
  border: 1px dashed #ddd;
  margin: 10px auto;
  min-height: 220px;
}
.strong {
  font-weight: bold;
}

.menu-box ul.menu-list > li a:before {
  content: "";
  position: absolute;
  width: 11px;
  height: 20px;
  border-bottom: 1px dotted #d7cccc;
  position: absolute;
  left: 0;
  top: 0;
}
.sblock label {
  background: rgba(203, 213, 225, 0.2);
  padding: 9px;
  display: block;
  overflow: hidden;
  user-select: none;
  margin: 0 !important;
  cursor: pointer;
}
.sblock {
  margin: 5px 0px 5px 0px;
  border: 1px solid #ddd;
  height: auto;
  list-style: none;
  position: relative;
  overflow: hidden;
}
.sblock ul {
  display: none;
}
#renderHtml {
  padding: 0 !important;
}
ul.menu-list li {
  position: relative;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
ul.menu-list {
  padding: 0 15px;
  border-left: 1px dotted #d7d5d5;
  list-style: none;
}

.search {
  flex: 2;
}

input#search-input {
  padding: 13px;
  margin: 11px 0px 11px 0px;
  border: 1px;
  outline: none;
  width: 100%;
}

input#search-input:focus {
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

button#search_btn {
  padding: 9px;
  max-height: 41px;
  border: none;
  cursor: pointer;
}

.language {
  flex: 1;
}

.language select {
  height: 41px;
  margin: 11px 0px 11px 0px;
  border: 1px;
  outline: none;
  font-size: 13.3333px;
}

.action_block a {
  color: #475569;
  cursor: pointer !important;
  padding: 0 !important;
}
.action_block {
  position: absolute;
  right: 12px;
  top: 9px;
  display: -webkit-inline-box;
  z-index: 9999;
  cursor: pointer !important;
}
.menu-box {
  border: 1px solid #a1a1a1;
  margin: 10px;
  padding: 10px;
}

.menu-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-box ul.menu-list li {
  display: block;
  margin-bottom: 5px;
}
.sblock label span {
  float: left;
  margin: 0px 10px 0 0;
}
ul.menu-list li a {
  overflow: hidden;
  display: flex;
  align-items: center;
}
ul.menu-list li a span.name {
}
.iconbox label {
  min-width: 28px;
}
ul.menu-list li a span.icons {
  margin: 0px 10px 0 8px;
  /* width: 4%; */
}
.sortable {
  min-height: 0 !important;
}

.selectBox {
  display: block;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
  width: 100%;
  margin: 10px 0;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='15px'%3E%3Ctext x='0' y='10' fill='lightgrey'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.5em 0.5em;
  background-position: right center;
  background-clip: border-box;
  -moz-background-clip: border-box;
  -webkit-background-clip: border-box;
}
select.selectBox::-ms-expand {
  display: none;
}
.menu-box ul.menu-list > li a {
  background: #ffffff00;
  display: block;
  font-size: 14px;
  color: #606060;
  /* text-transform: uppercase; */
  text-decoration: none;
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
}
.menu-box ul.menu-list > li a:hover {
  cursor: move;
}
.menu-box ul.menu-list ul {
  margin-left: 20px;
  margin-top: 5px;
}
.menu-box ul.menu-list ul li a {
  color: blue;
}
.menu-box li.menu-highlight {
  border: 1px dashed red !important;
  background: #f5f5f5;
}

.ui-tooltip-content {
  font-size: 12px !important;
}
.action_block a {
  margin-left: 10px;
  opacity: 0.3;
}
.action_block a:hover {
  opacity: 1;
}
.hidden {
  display: none;
}

ul.menu-list {
  border-left: 1px dotted #d7cccc;
}

.menu-box ul.menu-list > li:last-child a {
  border-bottom: none !important;
}

ul.menu-list li a span.name p {
  margin: 0;
}
#renderHtml .icons img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
