:root {
  --surface-primary-default: #fff;
  --border-basic-primary: #bdc7d1;
  --btn-basic-background-enabled: #fff;
  --action-primary-default: #4284f5;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");

/* @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap'); */

.form-control {
  border: 1px solid var(--border-basic-primary);
  border-radius: 8px;
  position: relative;
  transition: background-color 0.2s ease 0s;
  background: var(--surface-primary-default);
  margin-top: 2px;
}

.form-control {
  height: 34px;
  display: block;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}
.form-control:focus {
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-group {
  margin-bottom: 15px;
  text-align: start;
}

.add-group {
  display: flex !important;
  align-items: center;
  gap: 5px;
}

.add-group .form-control {
  margin-top: 0;
}

.form-control {
  height: 35px !important;
}

label {
  color: #18181b;
  font-size: 13px;
  font-weight: 600 !important;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
}
div#fb-editor .rendered-form .form-group {
  margin-bottom: 10px;
}
input,
select,
textarea {
  border-radius: 10px;
  border: 1px solid #dee5ec;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;

  font-weight: 300;
  line-height: 26px;
}

.formbuilder-submit {
  border-color: var(--border-basic-primary);
  background-color: var(--btn-basic-background-enabled);
  color: var(--action-primary-default);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  transition-property: opacity, border, color, background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 16px;
  min-width: 36px;
  height: 36px;
  text-align: center;
  text-decoration: none;
  font-size: 14px !important;
  font-weight: 400;
  user-select: none;
  margin-top: 10px;
}

.tabs {
  justify-content: center;
  display: flex;
  position: relative;
  /* border: 2px solid #EDEDED; */
  /* border-radius: 10px; */
}
.tab {
  justify-content: center;
  align-items: center;
  border: none;
  display: flex;
  position: relative;
  padding: 7px 10px;
  background-color: transparent;
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  flex: 1 1;
  font-family: "Poppins", sans-serif;
}

.tab.active {
  background: var(--action-primary-default);
  color: var(--surface-primary-default);
}
.tabs .tab {
  border: 1px solid #ededed;
}
.tab:first-child {
  border-radius: 4px 0 0 4px;
}
.tab.active {
  background: transparent;
  color: #7100e1;
  border-color: #7100e1 !important;
}

.tab:last-child {
  border-radius: 0 4px 4px 0;
}

.panel {
  display: none;
}
.panel.active {
  display: block;
}

.questionnaire-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
}

.questionnaire-text {
  border-color: var(--border-basic-primary);
  background-color: var(--btn-basic-background-enabled);
  color: #000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 16px;
  min-width: 36px;
  min-height: 36px;
  text-decoration: none;
  font-size: 15px !important;
  font-weight: 500;
  line-height: 20px;
  user-select: none;
  margin-top: 10px;
  text-align: start;
}

.questionnaire-text:hover {
  background-color: #f0f0f0;
}

.form-loader {
  z-index: 99999;
  position: relative;
}

.form-loader .preload-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-loader .preload-loader-wrapper {
  width: 56px;
  height: 56px;
  display: flex;
}
.form-loader-submit .preload-wrapper {
  position: fixed;
  top: 0;
  left: 0;
}
.form-loader .preload-loader {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  position: relative;
  border: 4px solid #deeefd;
  border-top-color: #4384f5;
}

.primary-btn {
  align-items: center;
  background-color: #7100e1;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 1.5;
  padding: 9px 17px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-property: opacity, border, color, background-color, box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  -webkit-user-select: none;
  user-select: none;
  text-transform: uppercase;
  border: none;
}
.subBtn {
  /* display: flex;
  align-items: center;
  justify-content: end; */
}
.primary-btn:disabled {
  background-color: #888;
  color: #d8d8d8;
}

.w-100 {
  width: 100%;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  overflow: auto;
}
.modal-content {
  background-color: #fff;
  margin: auto;
  border: 1px solid #888;
  width: 30%;
  position: relative;
  text-align: center;
}
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }

  .ReactModal__Content .modal-content {
    width: 100%;
  }
}
.modal .checkmark-circle,
.ReactModal__Content .checkmark-circle {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.modal-error .modal-top svg {
  width: 100px;
  height: 100px;
}

.modal-error .modal-top .cross-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto;
}

.modal .background,
.ReactModal__Content .background {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
}
.modal .checkmark,
.ReactModal__Content .checkmark {
  border-radius: 5px;
}
.modal .checkmark:after,
.ReactModal__Content .checkmark::after {
  opacity: 1;
  height: 55px;
  width: 17.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 11px solid #1ab394;
  border-top: 11px solid #1ab394;
  border-radius: 2.5px !important;
  content: "";
  left: 17px;
  top: 60px;
  position: absolute;
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
  animation-delay: 300ms;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17.5px;
    opacity: 1;
  }
  40% {
    height: 55px;
    width: 17.5px;
    opacity: 1;
  }
  100% {
    height: 55px;
    width: 17.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17.5px;
    opacity: 1;
  }
  40% {
    height: 55px;
    width: 17.5px;
    opacity: 1;
  }
  100% {
    height: 55px;
    width: 17.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17.5px;
    opacity: 1;
  }
  40% {
    height: 55px;
    width: 17.5px;
    opacity: 1;
  }
  100% {
    height: 55px;
    width: 17.5px;
    opacity: 1;
  }
}
.modal-top {
  background: #1ab394;
  padding: 30px 20px;
}

.modal-error .modal-top {
  background: #870000;
}
.modal-bottom {
  padding: 30px 20px;
}
.modal-bottom h2 {
  margin-top: 0;
}

.login-formWrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000094;
  padding: 0 20px;
}
.login-form {
  width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoutBtn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.logoutBtnNew {
  right: 0;
  top: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
}

.container-header-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 5px;
}

.text-header {
  color: #2e2e2e;
  /* text-align: center; */
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 20px;
  flex: 1 1;
  word-break: break-all;
  max-width: 90%;
  margin: 0px;
}

.lead-success {
  color: #1f441f;
  padding: 10px;
  background-color: #9ef29e;
  display: inline-flex;
  border: 1px solid #5b915b;
  margin-top: 0;
}

#errorToast {
  visibility: hidden;
  max-width: 400px;
  margin-left: -125px;
  background-color: #e72626;
  color: #fff;
  border-radius: 2px;
  padding: 10px;
  position: fixed;
  z-index: 1;
  right: 20px;
  top: 20px;
  font-size: 15px;
}

.close-button {
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  color: #999;
  padding-left: 5;
}

@media (max-width: 380px) {
  #errorToast {
    max-width: 250px;
  }
}

#errorToast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 20px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 20px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 20px;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 20px;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}

.form-wrapper {
  background-color: #fff;
  /* border: solid 1px #e4e8ec; */
  /* border-radius: 8px; */
  margin-top: 8px;
  /* padding:15px 18px; */
  font-size: 15px;
  line-height: 16px;
  overflow: hidden;
}

.rendered-form div[class=""]:nth-child(-n + 2) {
  display: none;
}

.search-language {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

#call-status {
  display: flex;
  align-items: center;
  gap: 5px;
}

#call-status label {
  color: #2e2e2e;
  font-family: "Poppins", sans-serif;
  font-size: 16px;

  font-weight: 400;
  line-height: 25px;
}
.search-language .search,
.search-language .language {
  background-color: #f8fafd;
  height: 35px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e4e4e7;
}
.search-language .search input#search-input {
  margin: 0;
  background: transparent;
  padding: 4px 13px;
  color: #999;
  font-size: 13px;
}
.search-language .search button#search_btn {
  background: transparent;
  padding: 6px 6px 5px;
}
.search-language .language select#languageSelect {
  margin: 0;
  background: transparent;
  height: 35px !important;
  font-size: 13px !important;
  color: #999;
}

div#renderHtml .call_to_action {
  border: 1px solid #e4e4e7;
  padding: 4px 13px;
  border-radius: 6px;
  margin: 10px 0;
}

div#renderHtml .call_to_action:empty {
  display: none;
}

div#renderHtml .call_to_action p font b {
  width: calc(100% - 20px);
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  background-color: #ccc;
  padding: 7px 10px;
  color: #000;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid #999;
}
div#renderHtml .call_to_action p {
  margin: 15px 0 10px;
}
div#renderHtml div.sblock {
  border-radius: 6px;
}
div#renderHtml div.sblock label {
  background: transparent;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 5px 9px;
  font-size: 12px;
}
div#renderHtml div.sblock label span {
  line-height: 16px;
  font-weight: 500;
}
.search-language .search input#search-input:focus,
.search-language .language select#languageSelect:focus {
  box-shadow: none;
}
/* div#renderHtml div.sblock:hover label {
  background: #f8fafd;
} */
ul.menu-list.sortable.ui-sortable {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}
ul.menu-list li {
  margin-bottom: 8px !important ;
}
.editModal .modal-bottom h2 {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
}
.editModal .modal-bottom {
  padding: 15px 15px;
}
.editModal .modal-bottom .form-group.add-group textarea {
  height: 100px !important;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
  resize: none;
  max-height: 100px !important;
  padding: 6px 6px !important;
}
.editModal .modal-bottom .form-group.add-group {
  display: inline-block !important;
  width: 100%;
  margin-bottom: 0 !important;
}
.editModal .modal-bottom .form-group.add-group input.primary-btn {
  padding: 5px 13px;
  font-size: 12px;
  text-transform: capitalize;
}
.editModal .modal-bottom .form-group.add-group span {
  display: flex;
  align-items: center;
  justify-content: end;
}
.editModal .modal-bottom .form-group.add-group:after {
  display: none;
}
.editModal .modal-bottom .form-group.add-group textarea:focus {
  box-shadow: none;
}
.modal-content.editModal {
  border-radius: 6px;
  border: none;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #00000038 !important;
}
button.choice_edit {
  display: flex;
  padding: 0 3px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

body #renderHtml {
  padding: 0 10px 0 0 !important;
  height: 70vh;
  overflow-y: auto !important;
}

/* width */
#renderHtml::-webkit-scrollbar {
  width: 10px;
  display: initial !important;
}

/* Track */
#renderHtml::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
#renderHtml::-webkit-scrollbar-thumb {
  background: #d8d8da;
}
p.call-available {
  text-align: center;
  color: #11c95e;
  background: #f4fcf7;
  font-size: 14px;
  padding: 5px 0;
  margin: 5px 0 5px;
}
p.call-not-available {
  text-align: center;
  color: red;
  background: #f4fcf7;
  font-size: 14px;
  padding: 5px 0;
  margin: 5px 0 5px;
}
span.statusDot {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin-left: 6px;
}
span.statusDot.available {
  background-color: #11c95e;
}
span.statusDot.notavailable {
  background-color: red;
}
